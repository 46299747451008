import React from "react";
import VideoItem from "./VideoItem";
import musicList from "./musicList";
import VideoCard from "./VideoCard";
import Menu from "./Menu";
//import Buttons from "./Buttons";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      videos: musicList,
      playList: "ТуОмадӣ",
      videoCardsHidden: false,
      chosenVideo: "DxdwKqqEMbs",
      chosenTheme: "Ту Омадӣ"
    };
    this.handleClick = this.handleClick.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
  }

  //changes the playlist to val
  //changes the theme title to valT (Tajik)
  //if a button is clicked then the video cards should be shown
  handleClick(val, valT) {
    this.setState({ playList: val });
    if (this.state.videoCardsHidden) {
      this.setState({ videoCardsHidden: false });
    }
    console.log(valT);
    this.setState({ chosenTheme: valT });
    this.className = "clickedButton";
  }

  //checked. This does change chosenVideo to id
  //if you console.log it doesn't show the change
  //this is because console.log is asynchronous
  //and it is called before state is changed
  loadVideo(id) {
    console.log(id);
    this.setState({
      videoCardsHidden: !this.state.videoCardsHidden,
      chosenVideo: id
    });
  }
  render() {
    //make an array of the videos in chosen category
    const filterVideos = this.state.videos.filter(vid =>
      vid.tags.includes(this.state.playList)
    );
    //console.log(filterVideos);
    //map over array and make videoCards for each
    const filteredVideos = filterVideos.map(vid => (
      <VideoCard
        key={vid.id}
        name={vid.name}
        source={vid.source}
		tags={vid.tags}
		thumb={vid.thumb}
		
        onClick={() => this.loadVideo(vid.id)}
      />
    ));

    //find the one chosen video
    const myVideo = this.state.videos.find(
      vid => vid.id === this.state.chosenVideo
    );
    console.log(myVideo);

    //todo: figure out how to use Buttons component with onclick
    //<Buttons onClick={this.handleClick} />

    return (
      <div>
        <div className="menu-div">
          <Menu />
        </div>
        <div className="top">
          <h1 className="title">Мусиқӣ</h1>
        </div>
        <div className="main-app">
          <div className="buttonSections">
            <h1 class="button-heading">Албом</h1>
            <div align="center" className="theme-buttons">
              <button
                onClick={() => this.handleClick("Биёед", "Биёед")}>
                Биёед
              </button>
              <button onClick={() => this.handleClick("ТуОмадӣ", "Ту Омадӣ")}>
                Ту Омадӣ
              </button>
			  <button onClick={() => this.handleClick("ҳама", "Ҳама")}>
                Ҳама
              </button>
              
            </div>    
          </div>
          <div className="chosenThemeDiv">
            <h1 className="chosenTheme">{this.state.chosenTheme}</h1>
          </div>
          <div className="displayVideos">
            {this.state.videoCardsHidden ? (
              <div>
                <VideoItem
                  source={myVideo.source}
                  id={myVideo.id}
                  name={myVideo.name}
                />
              </div>
            ) : null}
          </div>

          {this.state.videoCardsHidden ? null : (
            <div className="displayVideos">{filteredVideos} </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
