const allVideos = [
{id: "0cKJBEMG5RI", name: "Бимон бо ман",  source: "https://www.youtube.com/embed/0cKJBEMG5RI",  thumb: "https://i.ytimg.com/vi/0cKJBEMG5RI/hqdefault.jpg", tags: ["Биёед", "ҳама" ], }, 
{id: "TizEldATrU0", name: "Шодӣ мекунам",  source: "https://www.youtube.com/embed/TizEldATrU0", thumb: "https://i.ytimg.com/vi/TizEldATrU0/hqdefault.jpg", tags: ["Биёед" , "ҳама" ], }, 
{id: "DxdwKqqEMbs", name: "Эй Худои ман",  source: "https://www.youtube.com/embed/DxdwKqqEMbs", thumb: "https://i.ytimg.com/vi/DxdwKqqEMbs/hqdefault.jpg", tags: [ "Биёед", "ҳама" ], }, 
{id: "peHRMthjiDc", name: "Ишқи тоза дар дилам",  source: "https://www.youtube.com/embed/peHRMthjiDc", thumb: "https://i.ytimg.com/vi/peHRMthjiDc/hqdefault.jpg", tags: ["Биёед" , "ҳама" ], }, 
{id: "FHaxXc7nMwk", name: "Ба ман нигоҳ кун",  source: "https://www.youtube.com/embed/FHaxXc7nMwk", thumb: "https://i.ytimg.com/vi/FHaxXc7nMwk/hqdefault.jpg", tags: [ "Биёед", "ҳама" ], }, 
{id: "j79UxYwBR5g", name: "Эй Масеҳо, Шоҳам Туӣ",  source: "https://www.youtube.com/embed/j79UxYwBR5g", thumb: "https://i.ytimg.com/vi/j79UxYwBR5g/hqdefault.jpg", tags: [ "Биёед", "ҳама" ], }, 
{id: "2o8TnEbZphg", name: "Омадӣ, хуш омадӣ",  source: "https://www.youtube.com/embed/2o8TnEbZphg", thumb: "https://i.ytimg.com/vi/2o8TnEbZphg/hqdefault.jpg", tags: [ "Биёед", "ҳама" ], }, 
{id: "oif_ooidSnQ", name: "Ба дил гуфтам, чаро",  source: "https://www.youtube.com/embed/oif_ooidSnQ", thumb: "https://i.ytimg.com/vi/oif_ooidSnQ/hqdefault.jpg", tags: ["Биёед" , "ҳама" ], }, 
{id: "e639wVn3pJ8", name: "Биёед, биёед",  source: "https://www.youtube.com/embed/e639wVn3pJ8", thumb: "https://i.ytimg.com/vi/e639wVn3pJ8/hqdefault.jpg", tags: ["Биёед" , "ҳама" ], }, 
{id: "-kxlI6YzZ5M", name: "Исоро Дӯст Дорам",  source: "https://www.youtube.com/embed/-kxlI6YzZ5M", thumb: "https://i.ytimg.com/vi/-kxlI6YzZ5M/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "-MMRg0M5yEc", name: "Эй ёри меҳрубонам",  source: "https://www.youtube.com/embed/-MMRg0M5yEc", thumb: "https://i.ytimg.com/vi/-MMRg0M5yEc/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "xWGfvTo28mU", name: "Имшаб зи Шодиҳо",  source: "https://www.youtube.com/embed/xWGfvTo28mU", thumb: "https://i.ytimg.com/vi/xWGfvTo28mU/hqdefault.jpg?sqp=-oaymwEZCPYBEIoBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLDR3tKbOteWVirWkD-CnRlrtsphkA", tags: [ "ТуОмадӣ", "ҳама" ], }, 
{id: "nJwZ_mUU3SQ", name: "Исо Худовандам Туӣ",  source: "https://www.youtube.com/embed/nJwZ_mUU3SQ", thumb: "https://i.ytimg.com/vi/nJwZ_mUU3SQ/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "xC3WbwWFs3k", name: "Ту Омадӣ",  source: "https://www.youtube.com/embed/xC3WbwWFs3k", thumb: "https://i.ytimg.com/vi/xC3WbwWFs3k/hqdefault.jpg", tags: [ "ТуОмадӣ", "ҳама" ], }, 
{id: "MS3MDK9pyno", name: "Бо Ту Роҳ Равам",  source: "https://www.youtube.com/embed/MS3MDK9pyno", thumb: "https://i.ytimg.com/vi/MS3MDK9pyno/hqdefault.jpg", tags: [ "ТуОмадӣ", "ҳама" ], }, 
{id: "gZIBfWjS_Bg", name: "Аз Рӯзе Худои Ман",  source: "https://www.youtube.com/embed/gZIBfWjS_Bg", thumb: "https://i.ytimg.com/vi/gZIBfWjS_Bg/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "ERqaC-6AjwM", name: "Хуш Омадӣ",  source: "https://www.youtube.com/embed/ERqaC-6AjwM", thumb: "https://i.ytimg.com/vi/ERqaC-6AjwM/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "jeZzkNJKva0", name: "Туро мехоҳам Худоям",  source: "https://www.youtube.com/embed/jeZzkNJKva0", thumb: "https://i.ytimg.com/vi/jeZzkNJKva0/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "zIz3zXob4uY", name: "Худоё",  source: "https://www.youtube.com/embed/zIz3zXob4uY", thumb: "https://i.ytimg.com/vi/zIz3zXob4uY/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "H1XNo4q2288", name: "Чашмони пур ашқ",  source: "https://www.youtube.com/embed/H1XNo4q2288", thumb: "https://i.ytimg.com/vi/H1XNo4q2288/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "jIm2daFjgVg", name: "Агар Исо",  source: "https://www.youtube.com/embed/jIm2daFjgVg", thumb: "https://i.ytimg.com/vi/jIm2daFjgVg/hqdefault.jpg", tags: ["ТуОмадӣ" , "ҳама" ], }, 
{id: "2Cc4YpRpmtQ", name: "Худоеро таборак бихон",  source: "https://www.youtube.com/embed/2Cc4YpRpmtQ", thumb: "https://i.ytimg.com/vi/2Cc4YpRpmtQ/hqdefault.jpg", tags: [ "Биёед", "ҳама" ], }, 
];


export default allVideos;


